*,
*::before,
*::after {
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

:root {
    --clr-primary: #5200FF;
    --clr-secondary:#C610A9;
    --clr-thirdly: #1400FF;
    --clr-dark: #250942;
    --clr-light: #D9DDE6;
    --fw-normal: 400;
    --fw-bold: 700;
    --fw-black: 900;
}

body{
    font-family: 'Work Sans' , sans-serif;
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
}

a {
    text-decoration: none;
    color: #FFF;
}

.wrapper{
    margin: 0;
    display: flex;
}


/* ----- side bar ----- */

.menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 200px;
    height: 100%;
    background-color: black;
    z-index: 2;
}

.logo__container{
    text-align: center;
    padding-top: 1.5rem;
}

.nav__link{
    text-decoration: none;
    color: var(--clr-light);
    
}

.nav__list{
    line-height: 40px;
    list-style: none;
}

.menu .icon__burger {
    display: none;
}

/* ------ end sidebar ---------- */

/* ----- main body ------ */

.main {
    margin-left: 200px;
    width: 100%;
    background-image: url("../img/background_waves.svg");
    background-repeat: no-repeat;
    background-size:contain;
    position: relative;
}

.main__wrapper {
    max-width: 1280px;
    margin: auto;
}

.main__header-container {
    width: 100%;
}

.main__header{
    display: flex;
    color: #FFF;
    height: 250px;
    padding-left: 2rem;
}

.name {
    width: 50%;
    font-size: 3rem;
}

.welcome {
    width: 50%;
    font-family: 'Lobster', cursive;
}

/* ----- CTAs ----- */

.main__cta{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 4rem;
}

.cta__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 200px;
}


.cta__card-img {
    padding: 1.5rem;
    max-width: 200px;
}

.cta__card-btn {
    background: linear-gradient(180deg, #6222F2 0%, #D56CD5 100%);
    border-radius: 20px;
    width: 200px;
    height: 64px;
    color: #FFF;
    font-weight: var(--fw-bold);
    font-size: 1.5rem;
    text-align: center;
    line-height: 64px; 
}

/* ----- end CTAs ----- */



/* ----- about me section ----- */

.about{
    padding-top: 8rem;
}

.background__bodywaves {
    background-image: url(../img/background_body_waves.svg);
    background-size: cover;
    width: 100%;
    height: 90%;
    position: absolute;
    z-index: -1;
    top: 10%;
    left: 0;
}

.about__img-container{
    padding-top: 3rem;
}

.about__text-wrapper, .cv__text-wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 4rem;
    padding-right: 2rem;
}

.about__text-wrapper > div, .cv__text-wrapper > div{
    max-width: 50%;
}

.about__img, .cv__img{
    width: 100%;
}

.about__text, .cv__text{
    line-height: 1.75rem;
}

.about__text p, .cv__text p{
    text-align: justify;
}

.about__text-title, .cv__text-title{
    font-size: 3rem;
}

.about__text-container, .cv__text-container{
    padding: 2rem;
    background: rgba(72, 14, 236, 0.49);
    border-radius: 50px;
    box-shadow: 0px 9px 40px 5px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-family: 'Itim', cursive;
}

.about__text-container p, .cv__text-container p {
    font-size: 1.4rem;
}

.timeline{
    width: 100%;
    text-align: center;
    padding: 8rem;
    position: relative;
    overflow: hidden;
}

.arrows {
    position: absolute;
	width: 60px;
	height: 72px;
	left: 50%;
	margin-left: -27px;
    top:20px;
}

.arrows path {
	stroke: #2994D1;
	fill: transparent;
	stroke-width: 3px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}


.timeline__line{
    background-color:#5C16F6;
    width: 0.40rem;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 8rem;
}

.timeline__entry-right{
    float: right;
    width: 50%;
    position: relative;
    text-align: left;
    padding-top: 5rem;
    padding-bottom: 5rem;
    clear: both;
}

.timeline__entry-left{
    float: left;
    width: 50%;
    position: relative;
    text-align: right;
    padding-top: 5rem;
    padding-bottom: 5rem;
    clear: both;

}

.timeline__cicle-right{
    background-color: #DF80EF;
    border-radius:100%;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: -9px;
    z-index: 10;
    margin-top: 2rem;

}

.timeline__cicle-left{
    background-color: #DF80EF;
    border-radius:100%;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: -15px;
    z-index: 10;
    margin-top: 2rem;

}


.timeline__entry-text-right{
    opacity: 0;
    padding-left: 4rem;
}

.timeline__entry-text-left{
    opacity: 0;
    padding-right: 4rem;
}

.visible {
    opacity: 1;
    transition: opacity 2s ease-in, width 2s linear;
}

.timeline__entry-text-right h3, .timeline__entry-text-right h2,.timeline__entry-text-left h3, .timeline__entry-text-left h2 {
    margin: 0;
    line-height: 2rem;
}

.timeline__entry-text-right h3,.timeline__entry-text-left h3{
    padding-top: 1rem;
    font-size: 2rem;
}



/* ----- end of about me section ----- */


/* ----- start CV section ----- */

.cv{
    padding-top: 8rem;
}

.cv__img-curriculum{
    width: 100%;
    text-align: center;
    padding: 0 1rem;
}

.cv__img-curriculum img {
    max-width: 100%;
}

.cv__download {
    width: 100%;
    text-align: center;
    padding: 2rem;
    color: #7d13ea;
}

.cv__download a{
    color: #7d13ea;
    font-weight: 800;
    text-decoration: underline;
}
/* ------ end CV section ----- */



/* ----- end main body ----- */



/* ----- footer ----- */

footer {
    width: calc(100% - 200px);
    margin-left: 200px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 350px;
}

.register {
    bottom: 0;
    left: calc(50% - 200px);
    position: absolute;
    color: #FFFFFF;
    padding-bottom: 0.6rem;
}

.social {
    text-align: center;
    align-items: flex-end;
    padding: 20px 50px 0px 0px;
    font-size: 2.5rem;
    color: #FFF;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    padding-bottom: 4rem;
}

.footer__background {
    background-image: url(../img/footer_background_waves.svg);
    background-size: cover;
    width: 100%;
    height: 90%;
    position: absolute;
    z-index: -1;
    bottom: 0%;
    left: 0;
}

/* ----- end footer ----- */


/* RESPONSIVE
   ========================================================================= */

/* Tablets en horizonal y escritorios normales */
/* -------------------------------------------------------------------------  */

@media (min-width: 768px) and (max-width: 1199px) { 

}


/* Móviles en horizontal o tablets en vertical */
/* ------------------------------------------------------------------------- */

@media (max-width: 800px) {
    .wrapper{
        margin: 0;
        display: flex;
        flex-flow: column;
    }
    
    .main {
        margin: 0;
    }

    .main__header {
        display: flex;
        flex-flow: column;
        color: #000000;
        height: 250px;
        text-align: center;
        padding: 0;
    }

    .main__header-container {
        padding: 8rem 0;
    }

    .main__header h1, .main__header h2 {
        width: 100%;
    }

    .name {
        font-size: 2rem;
    }

    .welcome {
        font-size: 1.5rem;
    }

    .main__cta {
        padding-top: 3rem;
    }

    .menu {
        position: relative;
        width: 100%;
        z-index: 10;
        height: auto;
        background-color: #5200FF;
    }

    .nav a.icon__burger {
        display: block;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
    }

    nav ul {
        display: none;
    }

    .menu.responsive nav ul {
        display: block;
        transition: 0.9s ease;
    }   


    .about__img, .cv__img {display: none;}

    .about__text-wrapper > div, .cv__text-wrapper > div {
        max-width: 100%;
        margin: 0 2rem;
        padding: 0 1rem;
    }

    .about__text-title, .cv__text-title {
        font-size: 2rem;
    }

    .about__text-container p, .cv__text-container p {
        font-size: 1rem;
    }


    .timeline {
        padding: 1rem;
    }

    .timeline__entry-text-left {
        padding-right: 2rem;
    }

    .timeline__entry-text-right {
        padding-left: 2rem;
    }

    .timeline__entry-text-right h3, .timeline__entry-text-left h3 {
        padding-top: 1rem;
        font-size: 1rem;
    }

    .timeline__entry-text-right h3, .timeline__entry-text-right h2, .timeline__entry-text-left h3, .timeline__entry-text-left h2 {
        margin: 0;
        line-height: 1.5rem;
    }



    .footer {
        width: 100%;
        margin: 0;
    }

    .social {
        text-align: center;
        align-items: flex-end;
        font-size: 2.5rem;
        color: #FFF;
        height: 100%;
        display: flex;
        justify-content: center;
        gap: 2rem;
        width: 100%;
        padding: 0 0 4rem 0;
    }

    .register {
        bottom: 0;
        left: 0;
        position: absolute;
        color: #FFFFFF;
        padding-bottom: 0.6rem;
        text-align: center;
        width: 100%;
    }

}



/* Móviles en vertical */
/* ------------------------------------------------------------------------- */
@media (max-width: 480px) { 
    .about__img{display: none;}
}



/* In Construction pop up */

.popup, .popup2 {
    position: relative;
    /* display: inline-block; */
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .popup .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #ef4c4c;;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -95px;
    animation: fadeOut 2s;
    -webkit-animation: fadeOut 2s;
    transition: visibility ease-in-out 1s;
  }

  .popup2 .popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #ef4c4c;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    bottom: 22%;
    left: 50%;
    margin-left: -95px;
    animation: fadeOut 2s;
    -webkit-animation: fadeOut 2s;
    transition: visibility ease-in-out 1s;
  }

  .popup .popuptext::after, .popup2 .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #ef4c4c transparent transparent transparent;
  }
  .popup .show, .popup2 .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  @-webkit-keyframes fadeIn {
    from {opacity: 0;} 
    to {opacity: 1;}
  }
  @keyframes fadeIn {
      from {opacity: 0;}
      to {opacity:1 ;}
  }

  @keyframes fadeOut {
    from {opacity: 1;}
    to {opacity:0 ;}
}

@-webkit-keyframes fadeOut {
    from {opacity: 1;} 
    to {opacity: 0;}
  }


/* end of pop up */